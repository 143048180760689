<template>
  <el-container :id="data.vehicleNo" class="carDialogVue">
    <el-card class="box-card monitor-message" style="width: 350px">
      <div class="cars-info" @mousedown="mousedown">
        <i
          style="float: right; padding: 3px 0; cursor: pointer"
          @click.stop="closeDialog()"
          class="el-icon-close"
        ></i>
        <ul class="info">
          <li>
            <p>车牌号：{{ data.cph }}</p>
          </li>
          <li>
            <p>线路：{{ data.companyName }}</p>
          </li>
          <li>
            <p>司机：{{ data.driverName }}</p>
          </li>
          <li>
            <p>时间：{{ data.gpsTime }}</p>
          </li>
          <li>
            <p>ACC：{{ data.accStatus }}</p>
          </li>
          <li>
            <p>调度屏终端状态：{{ data.busOnlineStatus }}</p>
          </li>
          <li>
            <p>电量：{{ data.electric == -1 ? "-" : data.electric }}</p>
          </li>
          <li>
            <p>ADAS终端状态：{{ data.adasOnlineStatus }}</p>
          </li>
          <li>
            <p>速度：{{ data.velocity ? data.velocity : 0 }}公里/时</p>
          </li>
          <li>
            <p>位置：{{ data.address }}</p>
          </li>
          <li>
            <p
              class="textBlue"
              v-if="data.unProcessedAlarm"
              @click="toUndealAlarm"
            >
              报警状态：未处理报警 {{ data.unProcessedAlarm }} >>
            </p>
            <p v-else>报警状态：无</p>
          </li>
          <li>
            <p>最新报警：{{ data.latelyAlarmDate }}</p>
          </li>
        </ul>
      </div>
    </el-card>
  </el-container>
</template>

<script>
export default {
  name: "Window",
  props: {
    titlex: String,
    id: [String, Number],
    checkObj: Object,
    area: String,
    street: String
  },
  watch: {
    checkObj (curVal, oldVal) {
      if (curVal) {
        this.getData();
      }
    }
  },
  data () {
    return {
      color: "#66B1FF",
      title: "",
      activeName: "second",
      data: {},
      carDteailTableVisible: false, //显示车辆更多详细信息
      status: ["车辆在线", "车辆离线", "正在行驶", "车辆异常"]
    };
  },
  mounted () {
    this.getData();
  },

  methods: {
    getData () {
      this.data = this.checkObj;
    },
    closeDialog (e) {
      this.$emit("dialogVisible");
    },
    toCarDetail () {
      this.$emit("toCarDetailDialog");
    },
    mousedown (event) {
      this.selectElement = document.getElementById(this.data.vehicleNo);
      var div1 = this.selectElement;
      this.selectElement.style.cursor = "move";
      this.isDowm = true;
      var distanceX = event.clientX - this.selectElement.offsetLeft;
      var distanceY = event.clientY - this.selectElement.offsetTop;
      document.onmousemove = function (ev) {
        var oevent = ev || event;
        div1.style.left = oevent.clientX - distanceX + "px";
        div1.style.top = oevent.clientY - distanceY + "px";
      };
      document.onmouseup = function () {
        document.onmousemove = null;
        document.onmouseup = null;
        div1.style.cursor = "default";
      };
    },
    /**点击未处理报警 */
    toUndealAlarm () {
      this.$router.push({
        path: "alarmDeal",
        query: {
          type: "1",
          vehicleNo: this.data.vehicleNo,
          cph: this.data.cph
        }
      });
    }
  },

  filters: {
    filterCarType (item) {
      if (item == 0) {
        return "在线";
      } else if (item == 1) {
        return "离线";
      } else {
        return "异常";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.carDialogVue {
  position: absolute;
  border: 1px;
  top: 1px;
  right: 1px;
  z-index: 3999;
  .monitor-message {
    >>> .el-card__body {
      padding: 8px;
    }
    overflow: hidden;
    .cars-info {
      position: relative;
      .info {
        margin-bottom: 5px;
        .textBlue {
          color: #01adff;
          cursor: pointer;
        }
        li {
          height: 23px;
          line-height: 23px;
          letter-spacing: -0.34px;
          font-size: 14px;
          color: #666666;
          // padding-bottom: 10px;
          span {
            display: inline-block;
            letter-spacing: -0.34px;
            text-align: left;
            float: left;
            white-space: nowrap;
          }
          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .z-henlarge {
        position: absolute;
        width: 5rem;
        height: 4rem;
        top: 2rem;
        right: 0.5rem;
        border-radius: 3px;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
        display: flex;
        justify-content: center;
      }
    }
  }
}

.SchedulingPlatform {
  .carDialogVue {
    position: fixed;
  }
}
</style>

