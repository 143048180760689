<template>
  <div class="electronicMap">
    <el-row style="margin: 0; margin-top: 13px">
      <!-- 主场 -->
      <el-col :span="2">
        <div class="plan_div">
          <p class="plan_title">{{ companyName }}</p>
          <div>
            <span>全部计划</span>
            <span style="color: #00b9ff">{{ totalNums }}条</span>
          </div>
          <div>
            <span>新增计划</span>
            <span style="color: #ed6a0c">{{ newPlanNums }}条</span>
          </div>
          <div>
            <span>减少计划</span>
            <span style="color: #ed6a0c">{{ deletePlanNums }}条</span>
          </div>
          <div>
            <span>已完成</span>
            <span style="color: #2da641">{{ completedPlanNums }}条</span>
          </div>
          <div style="border-right: none">
            <span>未完成</span>
            <span style="color: #d40000">{{ incompletePlanNums }}条</span>
          </div>
        </div>
      </el-col>

      <!-- 车站详情 -->
      <el-col :span="22">
        <div class="middle">
          <div class="mmmap">
            <div class="main">
              <span>主场</span>
            </div>
            <div class="secondary">
              <span>副场</span>
            </div>
            <!-- <div class="down" v-if="zhuOptions.length!=1"> -->
            <div class="down">
              <ul ref="downUl">
                <li v-for="item in zhuOptions" :key="item.stationNo">
                  <i></i>
                  <span>{{ item.stationName }}</span>
                </li>
                <div class="bus-down">
                  <div class="line" ref="down">
                    <div
                      class="cus-dropdown"
                      v-for="(bus, index) in downBus"
                      :key="index"
                      :staion-num="bus.busStationNum"
                      :arrive-type="bus.arriveType"
                      :style="
                        'left:' +
                        (downStationSpace *
                          (bus.busStationNum -
                            1 +
                            (bus.arriveType == '1' ? 0 : 1) * 0.5) -
                          16) +
                        'px'
                      "
                    >
                      <span class="el-dropdown-link" @click="onDropDownClick">
                        <el-badge
                          :value="bus.busCodes.length"
                          class="item"
                          :hidden="bus.busCodes.length == 1"
                        >
                          <i :class="getBusIcon(bus.busCodes[0].status)"></i>
                        </el-badge>
                      </span>
                      <ul class="dropdown-list dropdown-up" :style="{zIndex:(selfPage?'9999':'100')}">
                        <li
                          v-for="code in bus.busCodes"
                          :key="code.busCodes"
                          :vehicleNo="code.vehicleNo"
                        >
                          {{ code.busCode }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
            <!-- <div class="up" v-if="fuOptions.length!=1"> -->
            <div class="up">
              <ul ref="upUl">
                <li v-for="item in fuOptions" :key="item.stationNo">
                  <i></i>
                  <span>{{ item.stationName }}</span>
                </li>
                <div class="bus-up">
                  <div class="line" ref="up">
                    <div
                      class="cus-dropdown"
                      v-for="(bus, index) in upBus"
                      :key="index"
                      :staion-num="bus.busStationNum"
                      :arrive-type="bus.arriveType"
                      :style="
                        'left:' +
                        (ulWidth -
                          upStationSpace *
                            (bus.busStationNum -
                              1 +
                              (bus.arriveType == '1' ? 0 : 1) * 0.5) -
                          16) +
                        'px'
                      "
                    >
                      <span class="el-dropdown-link" @click="onDropDownClick">
                        <el-badge
                          :value="bus.busCodes.length"
                          class="item"
                          :hidden="bus.busCodes.length == 1"
                        >
                          <i :class="getBusIcon(bus.busCodes[0].status)"></i>
                        </el-badge>
                      </span>
                      <ul class="dropdown-list dropdown-down" :style="{zIndex:(selfPage?'9999':'100')}">
                        <li
                          v-for="code in bus.busCodes"
                          :key="code.busCodes"
                          :vehicleNo="code.vehicleNo"
                        >
                          {{ code.busCode }}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 车辆详情面板 -->
    <car-dialog
      v-if="dialogTableVisible"
      :titlex="plate"
      :id="plate"
      :area="area"
      :street="street"
      :checkObj="carDetailObj"
      @toCarDetailDialog="handleToCarDetail"
      @dialogVisible="handleDialog"
    ></car-dialog>
  </div>
</template>

<script>
import {
  getLineAndStationsByCompanyIds,
  getPlanStatisticsBycompanyId,
  busDetailInfo,

} from "@/api/lib/bus-api";
import dialog from "../dialog/dialog.vue";
import { formatDay } from '@/common/utils/index.js'
import { queryBusPosition } from "@/api/lib/refreshAlarm";
export default {
  components: {
    "car-dialog": dialog
  },
  props: {
    companyId: {
      type: Number
    },
    isAllSimulate: {
      type: Boolean,
      default: false
    },
    selfPage:{
      type: Boolean,
      default: false
    }
  },

  created () {
    if (this.isAllSimulate) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.getAllStations();
      this.getStatistic();
      this.getBusInfo();
    }
  },

  data () {
    return {
      zhuOptions: [{ stationName: "" }],
      fuOptions: [{ stationName: "" }],

      dialogTableVisible: false, //显示车辆详情
      carDetailObj: {},
      area: "",
      street: "",
      color: "#66B1FF",
      plate: "", //当前选中车辆

      completedPlanNums: 0,
      deletePlanNums: 0,
      incompletePlanNums: 0,
      newPlanNums: 0,
      totalNums: 0,

      companyName: "",
      masterParkName: "",
      slaverParkName: "",

      zhuBus: [],
      fuBus: [],
      parkBus: [],
      busArr_afterDeal_zhu: [],
      busArr_afterDeal_fu: [],

      timer: null,
      busQuantity: 0,

      fuParkNumber: 0,
      zhuParkNumber: 0,

      ulWidth: 0,
      downBus: [],
      upBus: [],
      downStationSpace: 0,
      upStationSpace: 0,
      // 添加唯一标识,在组件销毁时区分不同组件的dropdown避免误删
      uniquelyIdentify: Number(
        Math.random()
          .toString()
          .substr(3, 5) + Date.now()
      ).toString(36)
    };
  },

  methods: {
    // 点击车牌号显示弹窗
    onCarClick (event) {
      let dom = event.target;
      if (dom.getAttribute("vehicleNo")) this.showCarDetail(dom.getAttribute("vehicleNo"));
    },
    // 点击dropdown
    onDropDownClick (event) {
      let dom = event.target.parentNode.parentNode.parentNode.children[1];
      dom.style.display =
        dom.style.display == "" || dom.style.display == "flex"
          ? "none"
          : "flex";
      this.drawDropdowns();
    },
    // 绘制dropdown
    drawDropdowns () {
      this.destroyDropDowns();
      // 上行
      const down = this.$refs.down.children;
      Array.from(down).forEach(e => {
        let h = e.children[0].children[0].clientHeight;
        let w = e.children[0].children[0].clientWidth;
        let top =
          e.getBoundingClientRect().top + document.documentElement.scrollTop;
        let left = e.getBoundingClientRect().left;
        let dd = e.children[1].cloneNode(true);
        dd.style.top = `${top - e.children[1].clientHeight - 10}px`;
        dd.style.left = `${left + 0.5 * w - 0.5 * e.children[1].clientWidth}px`;
        dd.setAttribute("uniquelyIdentify", this.uniquelyIdentify);
        if(!this.selfPage)dd.addEventListener("click", this.onCarClick);
        document.body.appendChild(dd);
      });
      // 下行
      const up = this.$refs.up.children;
      Array.from(up).forEach(e => {
        let h = e.children[0].children[0].clientHeight;
        let w = e.children[0].children[0].clientWidth;
        let top =
          e.getBoundingClientRect().top + document.documentElement.scrollTop;
        let left = e.getBoundingClientRect().left;
        let dd = e.children[1].cloneNode(true);
        dd.style.top = `${top + h + 5}px`;
        dd.style.left = `${left + 0.5 * w - 0.5 * e.children[1].clientWidth}px`;
        dd.setAttribute("uniquelyIdentify", this.uniquelyIdentify);
        if(!this.selfPage)dd.addEventListener("click", this.onCarClick);
        document.body.appendChild(dd);
      });
    },
    // 销毁dropdown
    destroyDropDowns () {
      const destroyNodes = document.querySelectorAll(
        'ul[uniquelyIdentify="' + this.uniquelyIdentify + '"]'
      );
      if (destroyNodes.length != 0) {
        Array.from(destroyNodes).forEach(e => {
          document.body.removeChild(e);
        });
      }
    },
    // 根据自定义属性获取元素
    GetAttrElement (tagid, attr, val) {
      var e = document.getElementById(tagid);
      var e = e.getElementsByClassName("product");
      var a = new Array();
      for (var i = 0; i < e.length; i++) {
        if (e[i].getAttribute(attr) == val) {
          return e[i];
        }
      }
      return false;
    },
    /* 获取站点信息 */
    getAllStations () {
      getLineAndStationsByCompanyIds({ companyIds: [this.companyId] }).then(res => {
        let data = res.data[0]
        if (data.downStationList) {
          this.zhuOptions = data.downStationList;
        } else {
          this.zhuOptions = [{ stationName: "" }];
        }
        if (data.upStationList) {
          this.fuOptions = data.upStationList.reverse();
        } else {
          this.fuOptions = [{ stationName: "" }];
        }
        this.masterParkName = data.masterName;
        this.slaverParkName = data.slaverName;
        this.companyName = data.companyName;
      });
    },

    /* 获取计划信息 */
    getStatistic () {
      getPlanStatisticsBycompanyId({ companyIds: [this.companyId], workDay: formatDay(new Date()) }).then(res => {
        if (res.data.length > 0) {
          let data = res.data[0]
          this.completedPlanNums = data.completedPlanNums;
          this.deletePlanNums = data.deletePlanNums;
          this.incompletePlanNums = data.incompletePlanNums;
          this.newPlanNums = data.newPlanNums;
          this.totalNums = data.totalNums;
        } else {
          this.completedPlanNums = 0;
          this.deletePlanNums = 0;
          this.incompletePlanNums = 0;
          this.newPlanNums = 0;
          this.totalNums = 0;
        }
      });
    },

    /* 获取车辆信息 */
    getBusInfo () {
      let self = this;
      let timerFn = function () {
        queryBusPosition({ companyId: self.companyId }).then(res => {
          // 下行
          // businessType => 1上行2下行
          const down = res.data.filter(
            item => item.stationInfo && item.stationInfo.businessType == "2"
          );
          let downArr = [];
          down.forEach(item => {
            if (downArr.length == 0) {
              downArr.push({
                busStationNum: item.stationInfo.stationNo,
                arriveType: item.stationInfo.arriveType,
                busCodes: [{ busCode: item.bus.cph, color: item.bus.vehicleColor, status: item.status, vehicleNo: item.vehicleNo }]
              });
            } else {
              let flag = false;
              downArr.forEach(arr => {
                if (
                  item.stationInfo.stationNo == arr.busStationNum &&
                  item.stationInfo.arriveType == arr.arriveType
                ) {
                  flag = true;
                  arr.busCodes = [
                    ...arr.busCodes,
                    { busCode: item.bus.cph, color: item.bus.vehicleColor, status: item.status, vehicleNo: item.vehicleNo }
                  ];
                }
              });
              if (!flag) {
                downArr.push({
                  busStationNum: item.stationInfo.stationNo,
                  arriveType: item.stationInfo.arriveType,
                  busCodes: [{ busCode: item.bus.cph, color: item.bus.vehicleColor, status: item.status, vehicleNo: item.vehicleNo }]
                });
              }
            }
          });
          self.downBus = downArr;

          // self.drawDropdowns();

          // 上行
          const up = res.data.filter(
            item => item.stationInfo && item.stationInfo.businessType == "1"
          );

          let upArr = [];
          up.forEach(item => {
            if (upArr.length == 0) {
              upArr.push({
                busStationNum: item.stationInfo.stationNo,
                arriveType: item.stationInfo.arriveType,
                busCodes: [{ busCode: item.bus.cph, color: item.bus.vehicleColor, status: item.status, vehicleNo: item.vehicleNo }]
              });
            } else {
              let flag = false;
              upArr.forEach(arr => {
                if (
                  item.stationInfo.stationNo == arr.busStationNum &&
                  item.stationInfo.arriveType == arr.arriveType
                ) {
                  flag = true;
                  arr.busCodes = [
                    ...arr.busCodes,
                    { busCode: item.bus.cph, color: item.bus.vehicleColor, status: item.status, vehicleNo: item.vehicleNo }
                  ];
                }
              });
              if (!flag) {
                upArr.push({
                  busStationNum: item.stationInfo.busStationNum,
                  arriveType: item.stationInfo.arriveType,
                  busCodes: [{ busCode: item.bus.cph, color: item.bus.vehicleColor, status: item.status, vehicleNo: item.vehicleNo }]
                });
              }
            }
          });
          self.upBus = upArr;

          setTimeout(() => {
            self.drawDropdowns();
          }, 200);

          // self.drawDropdowns();
        });
      };
      //立即执行一次
      timerFn();

      //设置定时器
      self.timer = setInterval(() => {
        timerFn();
      }, 5000);
    },

    /* 车辆数据一大堆处理 */
    dealBusArr (busArr_beforeDeal, type) {
      let repeatObj = {};
      let busArr_afterDeal = [];
      for (let i = 0; i < busArr_beforeDeal.length; i++) {
        if (
          repeatObj[
          `${busArr_beforeDeal[i].arriveType}-${busArr_beforeDeal[i].busStationNum}-${busArr_beforeDeal[i].color}-${busArr_beforeDeal[i].powerType}`
          ]
        ) {
          repeatObj[
            `${busArr_beforeDeal[i].arriveType}-${busArr_beforeDeal[i].busStationNum}-${busArr_beforeDeal[i].color}-${busArr_beforeDeal[i].powerType}`
          ] += `,${busArr_beforeDeal[i].busCode}`;
        } else {
          repeatObj[
            `${busArr_beforeDeal[i].arriveType}-${busArr_beforeDeal[i].busStationNum}-${busArr_beforeDeal[i].color}-${busArr_beforeDeal[i].powerType}`
          ] = busArr_beforeDeal[i].busCode;
        }
      }
      for (const key in repeatObj) {
        if (repeatObj.hasOwnProperty(key)) {
          let obj = {};
          obj.arriveType = key.split("-")[0];
          obj.busStationNum = key.split("-")[1];
          obj.color = key.split("-")[2];
          obj.powerType = key.split("-")[3];
          obj.busCode = repeatObj[key];
          obj.busCodeOption = obj.busCode.split(",");
          obj.businessType = type;
          busArr_afterDeal.push(obj);
        }
      }

      return busArr_afterDeal;
    },
    // 点击车牌号显示弹窗
    showCarDetail (vehicleNo) {
      busDetailInfo({ vehicleNo: vehicleNo }).then(res => {
        if (res.data) {
          this.plate = res.data.cph;
          this.carDetailObj = res.data;
          this.dialogTableVisible = true;
        }
      });
      this.dialogTableVisible = true;
    },
    /* 车辆图标 */
    getBusIcon (status) {
      if (status == 0 || status == undefined) {
        return "bus-norm";
      }
      if (status == 1 || status == -1) {
        return "bus-offline";
      }
      if (status == 2) {
        return "bus-repair";
      }
      if (status == 3) {
        return "bus-aerate";
      }
    },

    /* 关闭车辆基础信息面板 */
    handleDialog () {
      this.dialogTableVisible = false;
    },
    /* 打开车辆详细信息面板 */
    handleToCarDetail () {
      this.carDteailTableVisible = true;
    }
  },

  destroyed () {
    clearInterval(this.timer);
    this.destroyDropDowns();
  },

  computed: {
    /* 车场显示总宽度 */
    getWidth () {
      if (this.zhuOptions.length > this.fuOptions.length) {
        return `width:${this.zhuOptions.length * 91}px`;
      } else {
        return `width:${this.fuOptions.length * 91}px`;
      }
    }
  },
  mounted () {
    this.ulWidth = this.$refs.downUl.clientWidth;
  },
  watch: {
    companyId (n, o) {
      if (this.timer) {
        clearInterval(this.timer);
      }
      this.getAllStations();
      this.getStatistic();
      this.getBusInfo();
    },
    zhuOptions (val) {
      if (val.length > 1) {
        const width = this.$refs.downUl.clientWidth;
        this.downStationSpace = width / (val.length - 1);
      }
    },
    fuOptions (val) {
      if (val.length > 1) {
        const width = this.$refs.upUl.clientWidth;
        this.upStationSpace = width / (val.length - 1);
      }
    }
    // downBus: {
    //   handler() {
    //     this.drawDropdowns();
    //   },
    //   deep: true
    // },
    // upBus: {
    //   handler() {
    //     this.drawDropdowns();
    //   },
    //   deep: true
    // }
  }
};
</script>

<style lang="scss" scoped>
.electronicMap {
  .left,
  .middle,
  .right {
    height: 36vh;
    box-sizing: border-box;
    background-color: #f5f6fa;
  }
  .left {
    border-right: none;
    border-radius: 8px;
  }
  .middle {
    // padding: 55px 30px;
    // overflow-x: auto;
    overflow: hidden;
    border-radius: 0 8px 8px 0;
    display: flex;
    align-items: center;
  }
  .right {
    border-left: none;
    border-radius: 8px;
  }

  .round {
    background-color: #27ad06;
    color: #fff;
    font-size: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-weight: bold;
    height: 60px;
    text-align: center;
    line-height: 60px;
  }

  .roundName {
    height: 240px;
    width: 15px;
    margin: 0 auto;
    margin-top: 30px;
    word-wrap: break-word;
    word-break: break-all;
    line-height: 1.3;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 4px;
  }

  .stationDetail {
    display: inline-block;
    position: relative;
    margin-left: 40px;

    //车辆
    .bus_down {
      width: 60px;
      position: absolute;
      text-align: center;
      z-index: 99;
      bottom: -1px;
      font-size: 12px;
    }
    .bus_up {
      width: 60px;
      position: absolute;
      text-align: center;
      z-index: 99;
      top: -2px;
      font-size: 12px;
    }

    //站点
    p.station1 {
      height: 7px;
      width: 91px;
      position: relative;
      float: left;
      span.stationName1 {
        width: 14px;
        position: absolute;
        color: #666666;
        left: 2px;
        top: 20px;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        writing-mode: tb-rl;
        text-overflow: ellipsis;
        height: 8vh;
        text-align: start;
      }
    }
    p.station1::before {
      z-index: 99;
      content: "";
      display: inline-block;
      background-color: #fff;
      left: 4px;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      border: 2px solid #27ad06;
      position: absolute;
      top: 6px;
    }

    p.station2 {
      height: 7px;
      width: 91px;
      position: relative;
      float: left;

      span.stationName2 {
        width: 14px;
        position: absolute;
        color: #666666;
        right: 74px;
        bottom: 21px;
        font-size: 14px;
        white-space: nowrap;
        overflow: hidden;
        writing-mode: tb-rl;
        text-overflow: ellipsis;
        height: 8vh;
        text-align: end;
      }
    }
    p.station2::before {
      z-index: 99;
      content: "";
      display: inline-block;
      background-color: #fff;
      height: 5px;
      width: 5px;
      border-radius: 50%;
      border: 2px solid #27ad06;
      position: absolute;
      top: -11px;
      left: 5px;
    }
  }

  .el-divider--horizontal {
    margin: 0;
  }

  .plan_div {
    border: 1px solid rgba(220, 223, 230, 1);
    color: #666666;
    border-radius: 8px 0px 0px 8px;
    width: 100%;
    height: 33vh;
    padding: 1vh;
    font-size: 14px;
    margin-top: 5px;
    .plan_title {
      text-align: center;
      font-size: 18px;
      padding: 10px 0;
    }
    div {
      display: flex;
      justify-content: space-between;
      padding: 0 20px 10px 10px;
    }

    div:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }
    div:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }
  }

  // .el-col {
  //   padding: 0 !important;
  // }

  //主-副
  div.label1 {
    font-size: 12px;
    color: #fff;
    position: absolute;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    left: -18px;
    top: 7vh;
    background-color: #4b8dfd;
    border-radius: 50%;
    padding: 2px;
  }
  div.label1::after {
    width: 60px;
    height: 17vh;
    position: absolute;
    content: "";
    bottom: -7vh;
    right: -50px;
    border-top-left-radius: 6vh;
    border-bottom-left-radius: 6vh;
    background-color: rgba(0, 185, 255, 0.2);
  }
  div.label2 {
    font-size: 12px;
    color: #fff;
    position: absolute;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    right: -17px;
    bottom: 7vh;
    background-color: #4b8dfd;
    border-radius: 50%;
    padding: 2px;
  }
  div.label2::after {
    width: 60px;
    height: 17vh;
    position: absolute;
    content: "";
    bottom: -6.8vh;
    right: 20px;
    border-top-right-radius: 6vh;
    border-bottom-right-radius: 6vh;
    background-color: rgba(0, 185, 255, 0.2);
  }
}

.mmmap {
  // height: calc(150px + 1vh);
  width: 95%;
  height: 50%;
  border: 4px solid #27ad06;
  border-radius: 75px;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  .main,
  .secondary {
    position: absolute;
    height: 32px;
    width: 32px;
    background: #4b8dfd;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    span {
      color: #fff;
      font-size: 12px;
    }
  }
  .main {
    left: -18px;
  }
  .secondary {
    right: -18px;
  }
  &::before {
    width: 4.5%;
    height: 100%;
    position: absolute;
    content: "";
    border-top-left-radius: 8vh;
    border-bottom-left-radius: 8vh;
    background-color: rgba(0, 185, 255, 0.2);
    left: 0;
  }
  &::after {
    width: 67px;
    height: 100%;
    position: absolute;
    content: "";
    border-top-right-radius: 8vh;
    border-bottom-right-radius: 8vh;
    background-color: rgba(0, 185, 255, 0.2);
    right: 0;
  }
}

.up,
.down {
  width: 95%;
  height: 45%;
  ul {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    height: 100%;
    position: relative;
    li {
      width: 20px;
      font-size: 12px;
      display: inline-block;
      position: relative;
      display: flex;
      flex-direction: column;
      i {
        z-index:9;
        display: inline-block;
        content: "";
        background-color: #fff;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        border: 2px solid #27ad06;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
      span {
        height: 7vh;
        overflow: hidden;
        text-overflow: ellipsis;
        text-orientation: upright;
        white-space: nowrap;
        writing-mode: vertical-lr;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 16px;
        cursor: pointer;
      }
    }
  }
}

.down {
  i {
    top: -6px;
  }
  span {
    top: 0.5vh;
  }
  li {
    &:first-of-type,
    &:last-of-type {
      i {
        top: -3px;
      }
    }
  }
}

.up {
  i {
    bottom: -6px;
  }
  span {
    bottom: 0.5vh;
  }
  li {
    &:first-of-type,
    &:last-of-type {
      i {
        bottom: -3px;
      }
    }
  }
}

.bus-norm {
  display: inline-block;
  content: "";
  height: 24px;
  width: 24px;
  background: url("../../../../../assets/images/busIcon/bus_map_nor.png") no-repeat;
  background-size: 100%;
}

.bus-offline {
  display: inline-block;
  content: "";
  height: 24px;
  width: 24px;
  background: url("../../../../../assets/images/busIcon/bus_map_leave.png") no-repeat;
  background-size: 100%;
}

.bus-aerate {
  display: inline-block;
  content: "";
  height: 24px;
  width: 24px;
  background: url("../../../../../assets/images/busIcon/bus_map_aerate.png") no-repeat;
  background-size: 100%;
}

.bus-repair {
  display: inline-block;
  content: "";
  height: 24px;
  width: 24px;
  background: url("../../../../../assets/images/busIcon/bus_map_err.png") no-repeat;
  background-size: 100%;
}

div[class*="bus-"] {
  width: 100%;
  height: 8vh;
  // background: rgba($color: #4b8dfd, $alpha: 0.1);
  position: absolute;
  display: flex;
  flex-wrap: nowrap;
  .line {
    flex: 1;
    height: 24px;
    position: relative;
  }
}

.bus-down {
  top: -9vh;
  left: -0.25%;
  align-items: flex-end;
}

.bus-up {
  bottom: -10vh;
  left: -0.5%;
}

.cus-dropdown {
  display: inline-block;
  // position: relative;
  position: absolute;
  .dropdown-list {
    visibility: hidden;
    width: 4vw;
    height: auto;
    padding: 0.5vh 0;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    background: #fff;
    border-radius: 4px;
    z-index:9;
    li {
      width: 4vw;
      text-align: center;
      margin-bottom: 0.5vh;
      cursor: pointer;
    }
  }
  .dropdown-up {
    &::before {
      position: absolute;
      display: block;
      content: "";
      width: 0;
      height: 0;
      border-left: 0.5vh solid transparent;
      border-right: 0.5vh solid transparent;
      border-top: 0.75vh solid #ffffff;
      bottom: -0.75vh;
    }
  }
  .dropdown-down {
    &::before {
      position: absolute;
      display: block;
      content: "";
      width: 0;
      height: 0;
      border-left: 0.5vh solid transparent;
      border-right: 0.5vh solid transparent;
      border-bottom: 0.75vh solid #ffffff;
      top: -0.75vh;
    }
  }
}

.el-dropdown-link {
  cursor: pointer;
}

@media screen and (max-width: 1366px) {
  .electronicMap {
    .plan_div {
      font-size: 12px;
    }
  }
}
</style>

<style lang="scss">
.self-index{

}
.dropdown-list {
  width: 4vw;
  height: auto;
  padding: 0.5vh 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background: rgba($color: #4b8dfd, $alpha: 0.2);
  border-radius: 4px;
  // z-index: 2500;
  li {
    // width: 3vw;
    text-align: center;
    margin-bottom: 0.5vh;
    font-size: 12px;
    cursor: pointer;
  }
}
.dropdown-up {
  &::before {
    position: absolute;
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-left: 0.5vh solid transparent;
    border-right: 0.5vh solid transparent;
    border-top: 0.75vh solid rgba($color: #4b8dfd, $alpha: 0.2);
    bottom: -0.75vh;
  }
}

.dropdown-down {
  &::before {
    position: absolute;
    display: block;
    content: "";
    width: 0;
    height: 0;
    border-left: 0.5vh solid transparent;
    border-right: 0.5vh solid transparent;
    border-bottom: 0.75vh solid rgba($color: #4b8dfd, $alpha: 0.2);
    top: -0.75vh;
  }
}
</style>
