<template>
  <div class="simulateMap">
    <!-- 搜索 -->
    <el-select
      filterable
      multiple
      :multiple-limit="2"
      @change="changeLine"
      v-model="lineCode"
      placeholder="请选择或搜索线路"
      class="select"
      v-show="!isAllSimulate"
    >
      <el-option
        v-for="item in lineOptions"
        :key="item.companyId"
        :label="item.companyName"
        :value="item.companyId"
      ></el-option>
    </el-select>

    <!-- 选项 -->
    <el-tabs v-model="activeName">
      <el-tab-pane
        style="margin-top: -25px"
        v-for="(item, index) in tabList"
        :key="index"
        :label="item.companyName"
        :name="item.companyId.toString()"
      >
        <simulationMap
          style="margin-top: 20px"
          :isAllSimulate="true"
          :selfPage="pageLog"
          v-if="activeName == item.companyId"
          :companyId="item.companyId"
        ></simulationMap>
      </el-tab-pane>
    </el-tabs>
    <el-tabs v-model="activeName1">
      <el-tab-pane
        style="margin-top: -25px"
        v-for="(item, index) in tabList"
        :key="index"
        :label="item.companyName"
        :name="item.companyId.toString()"
      >
        <simulationMap
          :selfPage="pageLog"
          style="margin-top: 50px"
          :isAllSimulate="true"
          v-if="activeName1 == item.companyId"
          :companyId="item.companyId"
        ></simulationMap>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import simulationMap from "./map/map.vue";
import { queryAllBusLineList } from "@/api/lib/bus-api";

export default {
  components: {
    simulationMap
  },
  props: {
    lineId: {
      type: Number
    },
    isAllSimulate: {
      type: Boolean,
      default: false
    }
  },

  created () {
    if(this.lineId) this.pageLog=true;
    queryAllBusLineList().then(res => {
      if (res.code === 1000) {
        if (res.data.length == 0) {
          this.$message.warning("还没有线路信息，请先添加线路信息");
        } else {
          this.tabList = res.data;
          this.lineOptions = res.data;
          if (this.isAllSimulate) {
            this.lineCode = [this.lineId];
            this.activeName = this.lineId.toString();
          } else {
            this.lineCode = [res.data[0].companyId];
            this.activeName = res.data[0].companyId.toString();
          }
        }
      }
    });
    // this.tabList = [{ "classSystem": "一班制", "companyId": 8, "deleted": false, "lineCode": "11", "companyName": "1A路", "lineType": 2, "masterParkId": 34,  "slaverParkId": 32 }]
    // this.lineOptions = this.tabList;
    // this.activeName = this.tabList[0].companyId.toString();
    // this.lineCode = [this.tabList[0].companyId];
  },


  data () {
    return {
      activeName: "",
      activeName1: "",
      tabList: [],
      lineOptions: [],
      lineCode: [],
      pageLog:false,//是否从模拟地图进来
    };
  },

  methods: {
    changeLine (val) {
      if (val.length == 1) {
        this.activeName = val[0] + "";
        this.activeName1 = "";
      } else if (val.length == 2) {
        this.activeName1 = val[1] + "";
      } else if (val.length == 0) {
        this.activeName = "";
        this.activeName1 = "";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.simulateMap {
  width: 100%;
  height: 100%;
  border-radius: 4px;
  padding: 16px 20px;
  box-sizing: border-box;
  /deep/.el-tabs__header {
    display: none;
  }
  .select {
    margin-bottom: 2vh;
    width: 350px;
  }
}

.theme-project-bus {
  .simulateMap {
    background: #ffffff;
    box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  }
}
</style>