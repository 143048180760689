var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-container",
    { staticClass: "carDialogVue", attrs: { id: _vm.data.vehicleNo } },
    [
      _c(
        "el-card",
        {
          staticClass: "box-card monitor-message",
          staticStyle: { width: "350px" }
        },
        [
          _c(
            "div",
            { staticClass: "cars-info", on: { mousedown: _vm.mousedown } },
            [
              _c("i", {
                staticClass: "el-icon-close",
                staticStyle: {
                  float: "right",
                  padding: "3px 0",
                  cursor: "pointer"
                },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.closeDialog()
                  }
                }
              }),
              _c("ul", { staticClass: "info" }, [
                _c("li", [
                  _c("p", [_vm._v("车牌号：" + _vm._s(_vm.data.cph))])
                ]),
                _c("li", [
                  _c("p", [_vm._v("线路：" + _vm._s(_vm.data.companyName))])
                ]),
                _c("li", [
                  _c("p", [_vm._v("司机：" + _vm._s(_vm.data.driverName))])
                ]),
                _c("li", [
                  _c("p", [_vm._v("时间：" + _vm._s(_vm.data.gpsTime))])
                ]),
                _c("li", [
                  _c("p", [_vm._v("ACC：" + _vm._s(_vm.data.accStatus))])
                ]),
                _c("li", [
                  _c("p", [
                    _vm._v(
                      "调度屏终端状态：" + _vm._s(_vm.data.busOnlineStatus)
                    )
                  ])
                ]),
                _c("li", [
                  _c("p", [
                    _vm._v(
                      "电量：" +
                        _vm._s(
                          _vm.data.electric == -1 ? "-" : _vm.data.electric
                        )
                    )
                  ])
                ]),
                _c("li", [
                  _c("p", [
                    _vm._v("ADAS终端状态：" + _vm._s(_vm.data.adasOnlineStatus))
                  ])
                ]),
                _c("li", [
                  _c("p", [
                    _vm._v(
                      "速度：" +
                        _vm._s(_vm.data.velocity ? _vm.data.velocity : 0) +
                        "公里/时"
                    )
                  ])
                ]),
                _c("li", [
                  _c("p", [_vm._v("位置：" + _vm._s(_vm.data.address))])
                ]),
                _c("li", [
                  _vm.data.unProcessedAlarm
                    ? _c(
                        "p",
                        {
                          staticClass: "textBlue",
                          on: { click: _vm.toUndealAlarm }
                        },
                        [
                          _vm._v(
                            " 报警状态：未处理报警 " +
                              _vm._s(_vm.data.unProcessedAlarm) +
                              " >> "
                          )
                        ]
                      )
                    : _c("p", [_vm._v("报警状态：无")])
                ]),
                _c("li", [
                  _c("p", [
                    _vm._v("最新报警：" + _vm._s(_vm.data.latelyAlarmDate))
                  ])
                ])
              ])
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }